import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button, Typography } from '@mui/material'
import { Document, Page, StyleSheet, Text, View, Image, PDFViewer, Font } from '@react-pdf/renderer'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { webApiService } from '../../services'
import moment from 'moment'
import LogoReuse from '../../assets/img/logo-reuse.png'

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 10
  },
  section: {
    margin: 10
  },
  sectionFirma: {
    margin: 10,
    padding: 10,
    display: 'flex',
    flexDirection: 'row'
  },
  bloqueFirma: {
    borderTop: 1,
    textAlign: 'center',
    fontSize: 12,
    fontFamily: 'Times-Bold',
    paddingTop: '5px'
  },
  image: {
    width: '100%'
  },
  viewer: {
    width: '100%'
  }
})

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
})

const ReceiptPage = () => {
  const { id } = useParams()
  const [data, setData] = useState<{ loading: boolean; orden: any; order_pickup: any }>({ loading: false, orden: null, order_pickup: null })

  const loadData = async () => {
    try {
      setData(prevData => ({ ...prevData, loading: true }))
      const orderRes = await webApiService.getShopifyOrder(id)
      setData(prevData => ({ ...prevData, orden: orderRes.data.orders[0], loading: false }))
      const orderPickupRes = await webApiService.getOrder(id)
      setData(prevData => ({ ...prevData, order_pickup: orderPickupRes.data[0], loading: false }))
    } catch (error) {
      setData(prevData => ({ ...prevData, loading: false }))
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>

      <div className='bg-white rounded shadow py-4 px-3 mt-3'>
        <div className='container-fluid' style={{ paddingLeft: 0 }}>
          <div>
            <div className='row'>
              <div className='col-12 col-lg-10' style={{ display: 'flex', alignItems: 'left' }}>
                <Button variant='text' style={{ marginRight: '10px' }} color='error' size='small' href='/'><ArrowBackIcon color='primary' /></Button> <Typography variant='h5'>N° Orden: <strong>{id}</strong></Typography>
              </div>
              <div className='col-12 col-lg-2 shopify-button' />
            </div>
            <div className='col-12 pt-5 mt-2' style={{ borderTop: '1px solid #f5f5f5' }}>
              {
                data.orden !== null && data.order_pickup !== null
                  ? (
                    <PDFViewer style={styles.viewer} width='100%' height='600px'>
                      <Document>
                        <Page size='A4' style={styles.page}>
                          <View style={styles.section}>
                            <Image
                              style={{ width: '100px' }}
                              src={LogoReuse}
                            />
                          </View>
                          <View style={styles.section}>
                            <Text style={{ fontSize: 12, fontFamily: 'Times-Bold' }}>Estimado cliente, su pedido ha sido retirado usando el QR enviado a su correo {data.orden.customer?.email}. Se adjunta detalle de productos retirados y detalle de quién retiró</Text>
                          </View>
                          <View style={{ ...styles.section, marginBottom: '5px' }}>
                            <View style={{ flexDirection: 'row', marginBottom: '5px' }}>
                              <Text style={{ fontSize: 12, width: 150, fontFamily: 'Times-Bold' }}>Orden</Text>
                              <Text style={{ fontSize: 12, fontFamily: 'Times-Roman' }}>: {id}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', marginBottom: '5px' }}>
                              <Text style={{ fontSize: 12, width: 150, fontFamily: 'Times-Bold' }}>Cliente</Text>
                              <Text style={{ fontSize: 12, fontFamily: 'Times-Roman' }}>: {data.orden.customer?.first_name} {data.orden.customer?.last_name} </Text>
                            </View>
                            <View style={{ flexDirection: 'row', marginBottom: '5px' }}>
                              <Text style={{ fontSize: 12, width: 150, fontFamily: 'Times-Bold' }}>Email</Text>
                              <Text style={{ fontSize: 12, fontFamily: 'Times-Roman' }}>: {data.order_pickup?.collected_by_email}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', marginBottom: '5px' }}>
                              <Text style={{ fontSize: 12, width: 150, fontFamily: 'Times-Bold' }}>Fecha de compra</Text>
                              <Text style={{ fontSize: 12, fontFamily: 'Times-Roman' }}>: {moment(data.orden.created_at).format('DD/MM/YYYY - HH:mm:ss')}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', marginBottom: '5px' }}>
                              <Text style={{ fontSize: 12, width: 150, fontFamily: 'Times-Bold' }}>Forma de pago</Text>
                              <Text style={{ fontSize: 12, fontFamily: 'Times-Roman' }}>: {data.orden.payment_gateway_names}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', marginBottom: '10px' }}>
                              <Text style={{ fontSize: 12, width: 150, fontFamily: 'Times-Bold' }}>Fecha de entrega</Text>
                              <Text style={{ fontSize: 12, fontFamily: 'Times-Roman' }}>: {moment(data.orden.updated_at).format('DD/MM/YYYY - HH:mm:ss')}</Text>
                            </View>

                            <View>
                              <Text style={{ fontSize: 12, fontFamily: 'Times-Bold', backgroundColor: '#000', color: '#fff', textAlign: 'center', padding: '3px' }}>PRODUCTOS</Text>
                              {
                                data.orden !== null && data.orden.line_items.map((el: any, i: number) => {
                                  return (
                                    <Text key={i} style={{ fontSize: 12, fontFamily: 'Times-Roman', padding: '3px', border: 1 }}>{el.name}</Text>
                                  )
                                })
                              }

                            </View>
                          </View>

                          <View style={styles.sectionFirma}>
                            <View style={{ width: '50%' }}>
                              <Text />
                            </View>
                            <View style={{ width: '50%' }}>
                              <Image
                                style={styles.image}
                                src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.us-west-2.amazonaws.com/firmas/${id}.png`}
                              />
                              <Text style={styles.bloqueFirma}>Firma cliente</Text>
                            </View>
                          </View>
                        </Page>
                      </Document>
                    </PDFViewer>
                    )
                  : null
              }
            </div>
            <div className='row'>
              <div className='col-12 pt-4 mt-5 confirm-button' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', borderTop: '1px solid #f5f5f5' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReceiptPage
