import ReactDOM from 'react-dom/client'
import App from './App'
import { Provider as ReduxProvider } from 'react-redux'
import { store } from './store'
import { SnackbarProvider } from 'notistack'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <ReduxProvider store={store}>
    <SnackbarProvider maxSnack={5} autoHideDuration={4000}>
      <App />
    </SnackbarProvider>
  </ReduxProvider>
)
