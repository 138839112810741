
import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Header } from '../Header'

const Layout: React.FC = () => {
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 0)
    })
  }, [])

  return (
    <div className='wrapper d-flex flex-column my-5 p-1 h-100 w-100'>
      <Header />
      <main className='main'>
        <div className='container-xxxl'>
          <div className='content'>
            <nav className={scroll ? 'navbar navbar-light navbar-glass navbar-top navbar-expand navbar-glass-shadow' : 'navbar navbar-light navbar-glass navbar-top navbar-expand'} />
            <Outlet />
          </div>

        </div>
      </main>
    </div>
  )
}

export default Layout
