import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import LogoReuse from '../../../assets/img/logo-reuse.png'
import { FC } from 'react'
import moment from 'moment'

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 10
  },
  section: {
    margin: 10
  },
  sectionFirma: {
    margin: 10,
    padding: 10,
    display: 'flex',
    flexDirection: 'row'
  },
  bloqueFirma: {
    borderTop: 1,
    textAlign: 'center',
    fontSize: 12,
    fontFamily: 'Times-Bold',
    paddingTop: '5px'
  },
  image: {
    width: '100%'
  },
  viewer: {
    width: '100%'
  }
})

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
})

interface IOwnProps {
    firma: string
    orderPickup: any
    id: string
    orden: any
}

const ReceiptPdf : FC<IOwnProps> = ({ firma, orden, orderPickup, id }) => {
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.section}>
          <Image
            style={{ width: '100px' }}
            src={LogoReuse}
          />
        </View>
        <View style={styles.section}>
          <Text style={{ fontSize: 12, fontFamily: 'Times-Bold' }}>Estimado cliente, su pedido ha sido retirado usando el QR enviado a su correo {orden.customer?.email}. Se adjunta detalle de productos retirados y detalle de quién retiró</Text>
        </View>
        <View style={{ ...styles.section, marginBottom: '5px' }}>
          <View style={{ flexDirection: 'row', marginBottom: '5px' }}>
            <Text style={{ fontSize: 12, width: 150, fontFamily: 'Times-Bold' }}>Orden</Text>
            <Text style={{ fontSize: 12, fontFamily: 'Times-Roman' }}>: {id}</Text>
          </View>
          <View style={{ flexDirection: 'row', marginBottom: '5px' }}>
            <Text style={{ fontSize: 12, width: 150, fontFamily: 'Times-Bold' }}>Cliente</Text>
            <Text style={{ fontSize: 12, fontFamily: 'Times-Roman' }}>: {orden.customer.first_name} {orden.customer.last_name} </Text>
          </View>
          <View style={{ flexDirection: 'row', marginBottom: '5px' }}>
            <Text style={{ fontSize: 12, width: 150, fontFamily: 'Times-Bold' }}>Email</Text>
            <Text style={{ fontSize: 12, fontFamily: 'Times-Roman' }}>: {orderPickup.email}</Text>
          </View>
          <View style={{ flexDirection: 'row', marginBottom: '5px' }}>
            <Text style={{ fontSize: 12, width: 150, fontFamily: 'Times-Bold' }}>Fecha de compra</Text>
            <Text style={{ fontSize: 12, fontFamily: 'Times-Roman' }}>: {moment(orden.created_at).format('DD/MM/YYYY - HH:mm:ss')}</Text>
          </View>
          <View style={{ flexDirection: 'row', marginBottom: '5px' }}>
            <Text style={{ fontSize: 12, width: 150, fontFamily: 'Times-Bold' }}>Forma de pago</Text>
            <Text style={{ fontSize: 12, fontFamily: 'Times-Roman' }}>: {orden.payment_gateway_names}</Text>
          </View>
          <View style={{ flexDirection: 'row', marginBottom: '10px' }}>
            <Text style={{ fontSize: 12, width: 150, fontFamily: 'Times-Bold' }}>Fecha de entrega</Text>
            <Text style={{ fontSize: 12, fontFamily: 'Times-Roman' }}>: {moment(orden.updated_at).format('DD/MM/YYYY - HH:mm:ss')}</Text>
          </View>

          <View>
            <Text style={{ fontSize: 12, fontFamily: 'Times-Bold', backgroundColor: '#000', color: '#fff', textAlign: 'center', padding: '3px' }}>PRODUCTOS</Text>
            {
                                orden !== null && orden.line_items.map((el: any, i: number) => {
                                  return (
                                    <Text key={i} style={{ fontSize: 12, fontFamily: 'Times-Roman', padding: '3px', border: 1 }}>{el.name}</Text>
                                  )
                                })
                              }

          </View>
        </View>

        <View style={styles.sectionFirma}>
          <View style={{ width: '50%' }}>
            <Text />
          </View>
          <View style={{ width: '50%' }}>
            <Image
              style={styles.image}
              src={firma}
            />
            <Text style={styles.bloqueFirma}>Firma cliente</Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default ReceiptPdf
