import './theme/App.scss'
import { BrowserRouter, Navigate, Route } from 'react-router-dom'
import { RoutesWithNotFound } from './components/RoutesWithNotFound'
import ScrollToTop from './components/ScrollTop/ScrollTop'
import { HomePage } from './pages/HomePage'
import { Layout } from './components/Layout'
import { OrderDetails } from './pages/OrderDetails'
import { PrivateRoutes, PublicRoutes } from './constants'
import LoginPage from './pages/LoginPage/LoginPage'
import AuthGuard from './guards/auth.guard'
import ReceiptPage from './pages/ReceiptPage/ReceiptPage'

function App () {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <RoutesWithNotFound>
        <Route path='/' element={<Navigate to={PrivateRoutes.ORDERS_LIST} replace />} />
        <Route path={PublicRoutes.LOGIN} element={<LoginPage />} />
        <Route element={<Layout />}>
          <Route element={<AuthGuard privateValidation />}>
            <Route path={PrivateRoutes.ORDERS_LIST} element={<HomePage />} />
            <Route path={PrivateRoutes.ORDER_DETAILS + ':id'} element={<OrderDetails />} />
            <Route path={PrivateRoutes.RECEIPT + ':id'} element={<ReceiptPage />} />
          </Route>
        </Route>
      </RoutesWithNotFound>
    </BrowserRouter>
  )
}

export default App
