import axios from 'axios'

const axiosInstance = axios.create({
  headers: { 'Content-Type': 'application/json; charset=utf-8', Accept: 'text/json' }
})

export const webApiService = {
  getUserProfile: async (_email: string, _poolId: string) => {
    const url = `${process.env.REACT_APP_SERVICE_USER_PROFILE}getByEmailAndPoolId/${_email}/${_poolId}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },

  getProducts: async (data: any) => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_SERVICE_ORDERS_PICKUP}/getAllByActive`,
      data
    }

    try {
      const response = await axios.request(config)
      return response.data
    } catch (error) {
      console.error('Error en la solicitud:', error)
      throw error
    }
  },

  getShopifyOrder: async (data: any) => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_SERVICE_ORDERS_PICKUP}/getShopifyOrder`,
      data
    }

    try {
      const response = await axios.request(config)
      return response.data
    } catch (error) {
      console.error('Error en la solicitud:', error)
      throw error
    }
  },

  updateOrdersPickup: async (data: any) => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_SERVICE_ORDERS_PICKUP}/updateOrdersPickup`,
      data: {
        id_order: data.idOrder,
        client_data: data.clientData,
        id_user: data.userId,
        notificationEmail: data.notificationEmail
      }
    }

    try {
      const response = await axios.request(config)
      return response.data
    } catch (error) {
      console.error('Error en la solicitud:', error)
      throw error
    }
  },

  getOrder: async (data: any) => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_SERVICE_ORDERS_PICKUP}/getOrder`,
      data
    }

    try {
      const response = await axios.request(config)
      return response.data
    } catch (error) {
      console.error('Error en la solicitud:', error)
      throw error
    }
  },

  getFulFillment: async (data: any) => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_SERVICE_ORDERS_PICKUP}/getFulfillment`,
      data
    }

    try {
      const response = await axios.request(config)
      return response.data
    } catch (error) {
      console.error('Error en la solicitud:', error)
      throw error
    }
  },

  fulFillOrder: async (data: any) => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_SERVICE_ORDERS_PICKUP}/fulFillOrder`,
      data
    }

    try {
      const response = await axios.request(config)
      return response.data
    } catch (error) {
      console.error('Error en la solicitud:', error)
      throw error
    }
  },

  sendNotification: async (data: any) => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_SERVICE_SES_NOTIFICATION === undefined ? '' : process.env.REACT_APP_SERVICE_SES_NOTIFICATION}/sendNotification`,
      data
    }

    try {
      const response = await axios.request(config)
      return response.data
    } catch (error) {
      console.error('Error en la solicitud:', error)
      throw error
    }
  },

  checkOrderStatus: async () => {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_SERVICE_ORDERS_PICKUP}/checkOrderStatus`
    }

    try {
      const response = await axios.request(config)
      return response.data
    } catch (error) {
      console.error('Error en la solicitud:', error)
      throw error
    }
  },

  saveLog: async (data: any) => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_SERVICE_LOG}/save`,
      data
    }

    try {
      const response = await axios.request(config)
      return response.data
    } catch (error) {
      console.error('Error en la solicitud:', error)
      throw error
    }
  }
}
