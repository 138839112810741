import React, { useState, useEffect } from 'react'
import { LoadingButton } from '@mui/lab'
import { Card, CardContent, Dialog, DialogContent, DialogTitle, FormControl, IconButton, TextField, Toolbar, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { ModalProps } from '../../../../models'
import { Transition } from '../../../../constants/TransitionModal.contants'
import { CloseIcon } from '../../../../assets/icons/icons'

const ModalResetPassword: React.FC<ModalProps> = ({ modal, setModal }) => {
  const [data, setData] = useState<{ loading: boolean, newPass: string, confirmPassword: string}>({ loading: false, newPass: '', confirmPassword: '' })
  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    setModal({ ...modal, visible: false })
  }

  const handleChange = (e:any) => {
    e.preventDefault()
  }

  const setSuccess = () => {
    if (modal.parametros.isSuccess === 'success') {
      return enqueueSnackbar('Contraseña guardada exitosamente, por favor, ingrese con su nueva contraseña', { variant: 'success' })
    } else if (modal.parametros.isSuccess === 'error') {
      enqueueSnackbar('Tu contraseña debe tener 8 caracteres mínimo, debe incluir mayúscula, minúscula, número y caracter especial ', { variant: 'error' })
      handleClose()
    } else {
      return enqueueSnackbar('Necesitas crear una nueva contraseña por primera vez', { variant: 'warning' })
    }
  }

  useEffect(() => {
    setSuccess()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.parametros.isSuccess])
  return (
    <Dialog
      open={modal.visible}
      TransitionComponent={Transition}
      aria-labelledby='responsive-dialog-title'
      maxWidth='xs'
      fullWidth
    >
      <DialogTitle id='responsive-dialog-title' component='div' className='bg-azul p-0 text-white'>
        <Toolbar>
          <div className='col-12 text-center'>
            <Typography variant='h6' component='div' className='color-title'>
              <span>Nueva Contraseña</span>
            </Typography>
          </div>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#fff'
            }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <DialogContent dividers>
        <Card className='shadow-sm'>
          <CardContent>
            <div className='row gy-2'>
              <div className='col-12 my-3'>
                <FormControl fullWidth>
                  <TextField
                    margin='normal'
                    required
                    fullWidth
                    name='password'
                    value={data.newPass}
                    onChange={e => setData({ ...data, newPass: e.target.value })}
                    label='Nueva Contraseña'
                    type='password'
                    id='password'
                    autoComplete='current-password'
                    onCut={handleChange}
                    onCopy={handleChange}
                    onPaste={handleChange}
                  />
                  <TextField
                    margin='normal'
                    required
                    fullWidth
                    name='confirmPassword'
                    value={data.confirmPassword}
                    onChange={e => setData({ ...data, confirmPassword: e.target.value })}
                    label='Confirme nueva contraseña'
                    type='password'
                    id='confirmPassword'
                    autoComplete='current-confirmPassword'
                    onCut={handleChange}
                    onCopy={handleChange}
                    onPaste={handleChange}
                  />
                  <div className='col-12 text-center'>
                    <LoadingButton
                      type='submit'
                      fullWidth
                      onClick={() => modal.parametros.cnp(data.newPass, data.confirmPassword)}
                      variant='contained'
                      sx={{ mt: 3, mb: 2, paddingTop: 1.3, paddingBottom: 1.3, width: 200 }}
                    >
                      Reestrablecer contraseña
                    </LoadingButton>
                  </div>
                </FormControl>
              </div>
            </div>

          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>

  )
}

export default ModalResetPassword
