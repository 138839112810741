export const PublicRoutes = {
  LOGIN: '/login'
}

export const PrivateRoutes = {
  ORDERS_LIST: '/orders-list',
  ORDER_DETAILS: '/order-details/',
  PROFILE: '/profile',
  RECEIPT: '/order-receipt/'
}
