import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import checklist from '../../assets/img/checklist.png'
import { webApiService } from '../../services'
import { CurrencyFormat } from '../../utils/Currency.utility'
import { IAppStore } from '../../models'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import { pdf } from '@react-pdf/renderer'
import SignatureCanvas from 'react-signature-canvas'
import { blobToBase64 } from '../../utils/blobToBase64'
import ReceiptPdf from './components/ReceiptPdf'

const OrderDetails: React.FC = () => {
  const { id } = useParams()
  const userState = useSelector((store: IAppStore) => store.user)
  const [data, setData] = useState<{ loading: boolean; orden: any; order_pickup: any, signature: boolean }>({ loading: false, orden: {}, order_pickup: {}, signature: false })
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [useExistingCustomerData, setUseExistingCustomerData] = useState(false)
  const [pickupFormData, setPickupFormData] = useState<{ name: string, rut: string, email: string, phone: string, firma: any }>({
    name: '',
    rut: '',
    email: '',
    phone: '',
    firma: null
  })
  const navigate = useNavigate()
  const handleConfirmDelivery = () => { setOpenConfirmationModal(true) }
  const handleCancelConfirmation = () => { setOpenConfirmationModal(false) }
  const { enqueueSnackbar } = useSnackbar()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const signatureCanvasRef = useRef<any>(null)

  const clearSignature = async () => {
    const objLog = {
      app: process.env.REACT_APP_TITLE,
      action: 'clearSignature',
      data: `id orden: ${data.orden.id} | user: ${userState.name} (id: ${userState.id})`
    }
    await webApiService.saveLog(objLog)
    if (signatureCanvasRef.current !== null) {
      signatureCanvasRef.current.clear()
    }
  }

  const loadData = async () => {
    try {
      setData(prevData => ({ ...prevData, loading: true }))

      const orderRes = await webApiService.getShopifyOrder(id)
      console.log('🚀 ~ loadData ~ orderRes.data.orders[0]:', orderRes.data.orders[0])
      setData(prevData => ({ ...prevData, orden: orderRes.data.orders[0], loading: false }))

      const orderPickupRes = await webApiService.getOrder(id)
      setData(prevData => ({ ...prevData, order_pickup: orderPickupRes.data, loading: false }))
    } catch (error) {
      setData(prevData => ({ ...prevData, loading: false }))
      console.error('Error:', error)
    }
  }

  const handleAcceptConfirmation = async () => {
    const objLog = {
      app: process.env.REACT_APP_TITLE,
      action: 'handleAcceptConfirmation',
      data: `id orden: ${data.orden.id} | ¿firmó?: ${signatureCanvasRef.current.isEmpty() === true ? 'No' : 'Si'} | user: ${userState.name} (id: ${userState.id})`
    }
    await webApiService.saveLog(objLog)

    if (signatureCanvasRef.current.isEmpty()) {
      enqueueSnackbar('El cliente debe firmar', { variant: 'error' })
      return false
    }
    // console.log('🚀 ~ handleAcceptConfirmation ~ signatureCanvasRef.current.toData():', signatureCanvasRef.current.toDataURL('image/png'))

    try {
      setData({ ...data, loading: true })

      // Creamos PDF
      // eslint-disable-next-line no-debugger
      // debugger
      const pdfBlob = await pdf(<ReceiptPdf firma={signatureCanvasRef.current.toDataURL('image/png')} orderPickup={pickupFormData} orden={data.orden} id={String(id)} />).toBlob()
      console.log('🚀 ~ handleAcceptConfirmation ~ pdfBlob:', pdfBlob)
      const base64PDF = await blobToBase64(pdfBlob)
      console.log('🚀 ~ handleAcceptConfirmation ~ base64PDF:', base64PDF)

      // Obtenemos el id del Fulfillment con el id de la orden
      const getFulFillment = await webApiService.getFulFillment(data.orden.id)
      // Accedemos al último objeto del arreglo fulfillment_orders
      const fulfillmentOrders = getFulFillment.data.fulfillment_orders
      const fulFillmentOrderId = fulfillmentOrders[fulfillmentOrders.length - 1].id

      const fulFillOrder = await webApiService.fulFillOrder({ idOrder: Number(id), idFulfillment: fulFillmentOrderId })

      if (fulFillOrder.success === true) {
        const uop = await webApiService.updateOrdersPickup({ idOrder: Number(id), clientData: { ...pickupFormData, pdf: base64PDF }, userId: userState.id, notificationEmail: data.orden.customer.email })
        console.log('🚀 ~ handleAcceptConfirmation ~ uop:', uop)
        navigate('/')
        enqueueSnackbar('Orden: ' + id + ' entregada con éxito.', { variant: 'success' })
      } else {
        enqueueSnackbar('Oops ha habido un error, favor reintentelo nuevamente.', { variant: 'error' })
      }
    } catch (error) {
      setData({ ...data, loading: false })
      enqueueSnackbar('Error: ' + error, { variant: 'error' })
      console.log(error)
    }
    setOpenConfirmationModal(false)
  }

  const goToSignature = () => {
    setData({ ...data, signature: !data.signature })
  }

  const handleCheckboxClick = () => {
    const customerDataExists = data.orden.customer !== undefined && data.orden.customer !== null

    setUseExistingCustomerData(!useExistingCustomerData)

    if (!useExistingCustomerData && customerDataExists) {
      setPickupFormData({
        name: data.orden.customer.first_name && data.orden.customer.last_name
          ? `${data.orden.customer.first_name} ${data.orden.customer.last_name}`
          : '',
        rut: data.orden.customer.rut || '',
        email: data.orden.customer.email || '',
        phone: data.orden.customer.phone || '',
        firma: null
      })
    } else {
      setPickupFormData({
        name: '',
        rut: '',
        email: '',
        phone: '',
        firma: null
      })
    }
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (data.loading) return <div className='bg-white rounded shadow p-5' style={{ height: '1000px' }}>Cargando...  <CircularProgress color='primary' size={20} thickness={4} /></div>
  console.log('data.order_pickup[0]', data.order_pickup[0])
  console.log('signatureCanvasRef ==>', signatureCanvasRef)
  return (
    <div>

      <div className='bg-white rounded shadow py-4 px-3 mt-3'>
        <div className='container-fluid' style={{ paddingLeft: 0 }}>
          <div>
            <div className='row'>
              <div className='col-12 col-lg-10' style={{ display: 'flex', alignItems: 'left' }}>
                <Button variant='text' style={{ marginRight: '10px' }} color='error' size='small' href='/'><ArrowBackIcon color='primary' /></Button> <Typography variant='h5'>N° Orden: <strong>{id}</strong></Typography>
              </div>
              <div className='col-12 col-lg-2 shopify-button'>
                <Button variant='contained' style={{ marginRight: '10px' }} color='success' size='small' href={`https://admin.shopify.com/store/${process.env.REACT_APP_SHOPIFY_SLUG}/orders/${data.orden.id}`} target='_blank'>Ver orden en Shopify</Button>
              </div>
            </div>
            <div className='row col-12 pt-5 mt-2' style={{ borderTop: '1px solid #f5f5f5' }}>
              <div className='col-12 col-lg-4 text-center'>
                <img src={checklist} alt='order-box' style={{ width: '100%', maxWidth: '400px' }} />
              </div>
              <div className='col-12 col-lg-8'>
                <div className='col-12'>

                  <Chip
                    label={(() => {
                      if (data.order_pickup[0]?.shopify_status === null) return 'No preparado'.toUpperCase()
                      if (data.order_pickup[0]?.shopify_status === 'Ready for Pickup') return 'Listo para retiro'.toUpperCase()
                      if (data.order_pickup[0]?.shopify_status === 'Picked Up') return 'Retirado'.toUpperCase()
                      if (data.order_pickup[0]?.shopify_status === 'Picked Up Shopify') return 'Retirado Shopify'.toUpperCase()
                      return null
                    })()}
                    className='chip-status'
                    style={{
                      backgroundColor: data.order_pickup[0]?.shopify_status === 'Ready for Pickup' ? '#1976d2' : data.order_pickup[0]?.shopify_status === 'Picked Up' || data.order_pickup[0]?.shopify_status === 'Picked Up Shopify' ? '#4caf50' : '#fbc02d',
                      color: data.order_pickup[0]?.shopify_status === 'Ready for Pickup' ? '#fff' : data.order_pickup[0]?.shopify_status === 'Picked Up' || data.order_pickup[0]?.shopify_status === 'Picked Up Shopify' ? '#fff' : '#000',
                      fontSize: '12px'
                    }}
                  />
                  {data.orden.tags?.indexOf('TRADE-IN') !== undefined && data.orden.tags.indexOf('TRADE-IN') >= 0 && (
                    <Chip className='chip-status' label='TRADE-IN' style={{ marginLeft: '5px', backgroundColor: '#ffc107', border: '1px solid #cd9a00' }} />
                  )}
                  <Typography variant='h6' className='mt-2' style={{ fontSize: '1em' }}>
                    <strong>Cliente:</strong> {data.orden.customer?.first_name} {data.orden.customer?.last_name}
                  </Typography>
                  <Typography variant='h6' style={{ fontSize: '1em' }}><strong>Email:</strong> {data.orden.customer?.email}</Typography>
                  <Typography variant='h6' style={{ fontSize: '1em' }}><strong>Fecha de compra:</strong> {moment(data.orden.created_at).format('DD/MM/YYYY - HH:mm:ss')}</Typography>
                  <Typography variant='h6' style={{ fontSize: '1em' }}><strong>Forma de pago:</strong> {data.orden.payment_gateway_names}</Typography>
                </div>
                <div className='col-12 mt-5 table-desktop'>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                      <TableHead>
                        <TableRow style={{ background: '#333', color: '#fff' }}>
                          <TableCell align='right' className='bg-dark' style={{ color: '#fff' }}>Cantidad</TableCell>
                          <TableCell align='right' className='bg-dark' style={{ color: '#fff' }}>SKU</TableCell>
                          <TableCell className='bg-dark' style={{ color: '#fff' }}>Nombre</TableCell>
                          <TableCell align='right' className='bg-dark' style={{ color: '#fff' }}>Monto (CLP)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.orden.line_items && data.orden.line_items.map((producto: any, index: number) => (
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align='right'>{producto.quantity}</TableCell>
                            <TableCell align='right'>{producto.sku}</TableCell>
                            <TableCell align='left'>{producto.name}</TableCell>
                            <TableCell align='right'>{CurrencyFormat(producto.price)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div className='col-12 mt-5 table-mobile'>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                      <TableBody>
                        {data.orden.line_items && data.orden.line_items.map((producto: any, index: number) => (
                          <React.Fragment key={index}>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell align='left' className='bg-dark' style={{ color: '#fff' }}>Nombre</TableCell>
                              <TableCell align='left'>{producto.name}</TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell align='left' className='bg-dark' style={{ color: '#fff' }}>SKU</TableCell>
                              <TableCell align='left'>{producto.sku}</TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell align='left' className='bg-dark' style={{ color: '#fff' }}>Cantidad</TableCell>
                              <TableCell align='left'>{producto.quantity}</TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell align='left' className='bg-dark' style={{ color: '#fff' }}>Monto (CLP)</TableCell>
                              <TableCell align='left'>{CurrencyFormat(producto.price)}</TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>

              </div>
            </div>
            <div className='row'>
              <div className='col-12 pt-4 mt-5 confirm-button' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', borderTop: '1px solid #f5f5f5' }}>
                <Button style={{ marginLeft: '10px' }} variant='contained' size='medium' onClick={handleConfirmDelivery} disabled={data.order_pickup[0]?.shopify_status !== 'Ready for Pickup'}>Confirmar entrega</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal de confirmación */}
      <Dialog
        open={openConfirmationModal}
        onClose={handleCancelConfirmation}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Confirmar entrega</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            ¿Estás seguro de que deseas confirmar la entrega de producto(s)?
          </DialogContentText>
          {/* Checkbox for adding existing customer data */}
          {
          !data.signature
            ? (
              <div>

                <FormControlLabel
                  control={
                    <Checkbox
                      id='addExistingCustomerData'
                      color='primary'
                      onChange={handleCheckboxClick}
                    />
          }
                  label='Usar datos del titular de la orden.'
                />
                {/* Fields for the person who picks up the order */}
                <TextField
                  autoFocus
                  margin='dense'
                  id='pickupName'
                  label='Nombre y Apellido'
                  type='text'
                  fullWidth
                  required
                  value={pickupFormData.name}
                  onChange={(e) => {
                    const value = e.target.value
                    setPickupFormData({ ...pickupFormData, name: value })

                    // Verifica ambos campos al mismo tiempo
                    setIsButtonDisabled(!value.trim() || !pickupFormData.rut.trim())
                  }}
                />
                <TextField
                  margin='dense'
                  id='pickupRut'
                  label='RUT'
                  type='text'
                  fullWidth
                  required
                  value={pickupFormData.rut}
                  onChange={(e) => {
                    const value = e.target.value
                    setPickupFormData({ ...pickupFormData, rut: value })

                    // Verifica ambos campos al mismo tiempo
                    setIsButtonDisabled(!value.trim() || !pickupFormData.name.trim())
                  }}
                />
                <TextField
                  margin='dense'
                  id='pickupEmail'
                  label='Correo (Opcional)'
                  type='email'
                  fullWidth
                  value={pickupFormData.email}
                  onChange={(e) => setPickupFormData({ ...pickupFormData, email: e.target.value })}
                />
                <TextField
                  margin='dense'
                  id='pickupPhone'
                  label='Teléfono (Opcional)'
                  type='tel'
                  fullWidth
                  value={pickupFormData.phone}
                  onChange={(e) => setPickupFormData({ ...pickupFormData, phone: e.target.value })}
                />
              </div>
              )
            : (
              <div>
                <div>
                  <label>Firma:</label>
                </div>
                <div style={{ border: 'solid 1px rgba(0, 0, 0, 0.23)', display: 'inline-block' }}>
                  <SignatureCanvas penColor='black' canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }} ref={signatureCanvasRef} />
                </div>
                <div>
                  <Button variant='contained' onClick={clearSignature}>
                    Limpiar
                  </Button>
                </div>
              </div>
              )
         }

        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleCancelConfirmation} color='error'>
            Cancelar
          </Button>
          <Button variant='contained' onClick={data.signature ? handleAcceptConfirmation : goToSignature} autoFocus disabled={isButtonDisabled}>
            {data.signature ? 'Aceptar' : 'Firma cliente'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default OrderDetails
