import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { IAppStore } from '../models'
import { PublicRoutes } from '../constants'

interface Props {
    privateValidation: boolean;
  }

export const AuthGuard = ({ privateValidation }: Props) => {
  const userState = useSelector((store: IAppStore) => store.user)

  return userState.logged
    ? (
      <Outlet />
      )
    : (
      <Navigate replace to={PublicRoutes.LOGIN} />
      )
}

export default AuthGuard
