import { Route, Routes } from 'react-router-dom'
import React from 'react'
import { NotFound } from '../../pages/NotFound'

interface IOwnProps {
  children?: React.ReactNode
}

const RoutesWithNotFound: React.FC<IOwnProps> = ({ children }) => {
  return (
    <Routes>
      {children}
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}
export default RoutesWithNotFound
