import LogoReuse from '../../assets/img/logo-reuse-alargado.png'
import { NavLink /* Navigate */ } from 'react-router-dom'
import { Offcanvas } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { PrivateRoutes } from '../../constants'
import { ButtonMenu } from '../ButtonMenu'
import { LogoutButton } from '../LogoutButton'

const Header = () => {
  /* const userState = useSelector((store: IAppStore) => store.user) */

  return (
    <Navbar expand='md' className='bg-white shadow-sm fixed-top'>
      <Container fluid>
        <Navbar.Brand href='/' className='text-dark'>
          <img src={LogoReuse} alt='Logo Reuse' width='100px' />{' '}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='offcanvasNavbar-expand-md' />
        <Navbar.Offcanvas
          id='offcanvasNavbar-expand-expand-md'
          aria-labelledby='offcanvasNavbarLabel-expand-expand-md'
          placement='end'
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id='offcanvasNavbarLabel-expand-expand-md'>
              Menú
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className='p-0'>

            <Nav className='w-100 d-flex'>
              <div className='d-flex flex-column flex-md-row h-100 align-items-lg-center'>
                <NavLink to={PrivateRoutes.ORDERS_LIST} className='navbar px-2 text-decoration-none py-1 py-lg-0'>Inicio</NavLink>
              </div>
              <Navbar.Collapse className='justify-content-end mt-auto px-2'>
                <Navbar.Text className='text-dark fw-bold'>
                  <ButtonMenu />
                  <LogoutButton />
                </Navbar.Text>
              </Navbar.Collapse>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}

export default Header
