import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js'
import { Box, Checkbox, FormControlLabel, Grid, Link, TextField } from '@mui/material'
import ModalResetPassword from '../ModalResetPassword/ModalResetPassword'
import ModalForgotPassword from '../ModalForgotPassword/ModalForgotPassword'
import LoadingButton from '@mui/lab/LoadingButton'
import { useSnackbar } from 'notistack'
import { UserPool } from '../../../../utils'
import { webApiService } from '../../../../services'
import { PrivateRoutes } from '../../../../constants'
import { useAppDispatch } from '../../../../store/store'
import { createUser } from '../../../../store/user'

export interface LoginFormInterface { }

const LoginForm: React.FC<LoginFormInterface> = () => {
  const [data, setData] = useState({ username: '', password: '', loading: false, newPass: '' })
  const [modal, setModal] = React.useState<{ visible: boolean, name: string, parametros: any }>({ visible: false, name: '', parametros: {} })
  const [forgotModal, setForgotModal] = React.useState<{ visible: boolean, name: string, parametros: any }>({ visible: false, name: '', parametros: {} })

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const setNewPass = (_newPass: string) => {
    setData({ ...data, newPass: _newPass })
  }

  const forgotPass = () => {
    setForgotModal({ ...forgotModal, visible: true })
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setData({ ...data, loading: true })
    /*
    if (!modal.visible) {
      setData({ ...data, loading: false })
    } else {
      setData({ ...data, loading: true })
    }
    */

    const user = new CognitoUser({
      Username: data.username,
      Pool: UserPool
    })
    const authDetails = new AuthenticationDetails({
      Username: data.username,
      Password: data.password
    })

    user.authenticateUser(authDetails, {
      onSuccess: (response) => {
        const _poolId = process.env.REACT_APP_COGNITO_POOL_ID || ''
        webApiService.getUserProfile(response.getIdToken().payload.email, _poolId).then((res) => {
          // console.log('getUserProfile RES : ', res)
          if (res.success === true) {
            res.data.token = response.getIdToken().getJwtToken()
            res.data.logged = true
            dispatch(createUser(res.data))
            navigate(PrivateRoutes.ORDERS_LIST)
          } else {
            enqueueSnackbar('getUserProfile RES ERROR : ' + res.message, { variant: 'error' })
          }
        }).catch((error: any) => {
          console.log('getProfile error:', error)
          enqueueSnackbar('Hubo un error con token vacío, revisar usuario en base de datos ...', { variant: 'error' })
        }).finally(() => {
          setData({ ...data, loading: false })
        })
      },
      onFailure: (err) => {
        setData({ ...data, loading: false })
        // TypeError: callback.newPasswordRequired is not a function at CognitoUser.authenticateUserInternal
        err.message === 'callback.newPasswordRequired is not a function' ? setModal({ ...modal, visible: true, parametros: { oldPass: data.password, currentUser: user, currentUserName: data.username, setNewPass, modalName: 'temporary' } }) : console.log(err)
        if (err.message === 'Incorrect username or password.') {
          enqueueSnackbar('Usuario o contraseña incorrectos, por favor, intente nuevamente', { variant: 'error' })
        } else {
          enqueueSnackbar('authenticateUser onFailure IF : ' + err.message, { variant: 'error' })
        }
        setData({ ...data, loading: false })
      },
      newPasswordRequired (userAttributes, requiredAttributes) {
        setModal({
          ...modal,
          visible: true,
          parametros: {
            oldPass: data.password,
            currentUser: user,
            currentUserName: data.username,
            cnp: (newPass: string, confirmPassword: string) => {
              if (newPass === '' || confirmPassword === '') {
                enqueueSnackbar('No puedes dejar campos vacios', { variant: 'error' })
                return
              }
              if (newPass !== confirmPassword) {
                enqueueSnackbar('Las contraseñas no coinciden, por favor, intente nuevamente', { variant: 'error' })
                return
              }
              delete userAttributes.email_verified
              delete userAttributes.email
              user.completeNewPasswordChallenge(confirmPassword, userAttributes, {
                onSuccess: result => {
                  setModal({ ...modal, visible: false, parametros: { isSuccess: 'success' } })
                  enqueueSnackbar('Contraseña guardada exitosamente, por favor, ingrese con su nueva contraseña', { variant: 'success' })
                  setData({ ...data, loading: false, username: '', password: '' })
                  /*
                  setTimeout(() => {
                    window.location.replace('')
                  }, 3000)
                */
                },
                onFailure: (err) => {
                  console.log('ON FAILURE ERROR LOGIN FORM: ', err)
                  setModal({ ...modal, visible: true, parametros: { isSuccess: 'error' } })
                }
              })
            }
          }
        })
        if (modal.visible) {
          setData({ ...data, loading: true })
        } else {
          setData({ ...data, loading: false })
        }
      }
    })
  }

  return (
    <Box component='form' onSubmit={handleSubmit} noValidate>
      {modal.visible ? <ModalResetPassword modal={modal} setModal={setModal} /> : null}
      {forgotModal.visible ? <ModalForgotPassword modal={forgotModal} setModal={setForgotModal} /> : null}
      <TextField
        margin='normal'
        required
        fullWidth
        value={data.username}
        onChange={e => setData({ ...data, username: e.target.value })}
        id='email'
        label='Email'
        name='email'
        autoComplete='email'
        autoFocus
      />
      <TextField
        margin='normal'
        required
        fullWidth
        name='password'
        value={data.password}
        onChange={e => setData({ ...data, password: e.target.value })}
        label='Password'
        type='password'
        id='password'
        autoComplete='current-password'
      />
      <FormControlLabel
        control={<Checkbox value='remember' color='primary' />}
        label='Remember me' hidden
      />
      <LoadingButton
        type='submit'
        fullWidth
        variant='contained'
        sx={{ mt: 3, mb: 2, paddingTop: 1.3, paddingBottom: 1.3 }}
        loading={data.loading}
      >
        Ingresar
      </LoadingButton>
      <Grid container>
        <Grid item xs>
          <Link href='#' variant='body2' onClick={forgotPass}>
            Olvidaste tu password?
          </Link>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LoginForm
