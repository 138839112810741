import QrCodeIcon from '@mui/icons-material/QrCode'
import { Box, Button, Chip, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Pagination from '@mui/material/Pagination'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import moment from 'moment'
import { enqueueSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import QrReader from 'react-qr-scanner'
import { useNavigate } from 'react-router-dom'
import useWebSocket from 'react-use-websocket'
import { webApiService } from '../../services' // Asegúrate de importar CurrencyFormat desde el archivo correcto
import { CurrencyFormat } from '../../utils/Currency.utility'
import CachedIcon from '@mui/icons-material/Cached'
import shopifyLogo from '../../assets/img/shopify_logo.png'
import ImageIcon from '@mui/icons-material/Image'

const ITEMS_PER_PAGE = 15
const evenRowColor = '#ffffff'
const oddRowColor = '#f5f5f5'

const HomePage: React.FC = () => {
  const [data, setData] = useState<{
    loading: boolean;
    ordenes: any[];
    active: null | 0 | 1;
    currentPage: number;
    totalRecords: number;
    lastUpdateTime: number;
    scanData: string | null;
    modalSendQROpen: boolean;
    modal: { name: string, open: boolean, syncMessage: string };
    detailsData: any;
    socketUrl: string;
    messageHistory: any[];
    originalOrders: any[];
  }>({
    loading: false,
    ordenes: [],
    active: 1,
    currentPage: 1,
    totalRecords: 0,
    lastUpdateTime: 0,
    scanData: null,
    modalSendQROpen: false,
    modal: { name: '', open: false, syncMessage: '' },
    detailsData: null,
    socketUrl: 'wss://' + String(process.env.REACT_APP_SOCKET_ID) + '.execute-api.us-west-2.amazonaws.com/' + String(process.env.REACT_APP_ENVIRONMENT) + '/',
    messageHistory: [],
    originalOrders: []
  })

  const handleWebSocketMessage = (messageData: any) => {
    console.log('WebSocket Message Received:', messageData)

    if (messageData.eventType === 'websocketTrigger') {
      loadData()
    }

    setData((prevData) => ({ ...prevData, messageHistory: [...prevData.messageHistory, messageData] }))
  }

  const { sendMessage } = useWebSocket(data.socketUrl, {
    onMessage: (event: MessageEvent) => {
      const messageData = JSON.parse(event.data)
      handleWebSocketMessage(messageData)
    },
    shouldReconnect: (closeEvent: any) => true
  })

  const navigate = useNavigate()

  const handleError = (error: Error) => { console.error(error) }
  const openModal = (name: string, rowData?: any) => {
    setData({ ...data, detailsData: rowData === undefined ? [] : rowData, modal: { name, open: true, syncMessage: '' } })
  }
  const closeModal = async () => {
    if (data.modal.name === 'syncModal') {
      await loadData()
    }
    setData({ ...data, modal: { name: '', open: false, syncMessage: '' } })
  }
  const handleTabChange = (
    event: React.ChangeEvent<{}>,
    newValue: null | 0 | 1
  ) => {
    setData({ ...data, active: newValue, currentPage: 1 })
  }

  const handlePageSelectorChange = (event: any) => {
    setData({ ...data, currentPage: event.target.value })
  }

  const loadData = async () => {
    setData({ ...data, loading: true })
    await webApiService.getProducts({ active: data.active }).then((res) => {
      const allOrders = res.data
      const filteredOrders = allOrders
      const startIndex = (data.currentPage - 1) * ITEMS_PER_PAGE
      const paginatedOrders = filteredOrders.slice(startIndex, startIndex + ITEMS_PER_PAGE)
      console.log('filteredOrders ==>', filteredOrders)
      setData({ ...data, ordenes: paginatedOrders, loading: false, totalRecords: filteredOrders.length, lastUpdateTime: Date.now(), originalOrders: allOrders })
    })
      .catch((error) => {
        setData({ ...data, loading: false, lastUpdateTime: 0 })
        console.error(error)
      })
  }

  const handleScan = (scannedData: any) => {
    if (scannedData && scannedData.text && scannedData.text !== data.scanData) {
      setData({ ...data, scanData: scannedData.text })
      setTimeout(() => {
        navigate('/order-details/' + String(scannedData.text))
        setData({ ...data, scanData: null })
      }, 500)
    }
  }

  const handleSearch = (searchTerm: string) => {
    const filteredOrders = data.originalOrders.filter((order: any) => {
      const nameMatch = order.name?.toLowerCase().includes(searchTerm.toLowerCase())
      const emailMatch = order.email?.toLowerCase().includes(searchTerm.toLowerCase())
      const idOrderMatch = String(order.id_order_platform).toLowerCase().includes(searchTerm.toLowerCase())

      return nameMatch || emailMatch || idOrderMatch
    })

    setData({ ...data, ordenes: filteredOrders, currentPage: 1, totalRecords: filteredOrders.length })
  }

  const handleAcceptQRConfirmation = async (customerData: any) => {
    try {
      const email = process.env.REACT_APP_ENVIRONMENT === 'dev' ? process.env.REACT_APP_DEV_EMAIL : customerData.email
      const s3Key = `qrcodes/${customerData.id_order_platform as string}.png`
      await webApiService.sendNotification({
        templateName: 'ConfirmPickup',
        toEmail: [email],
        nameApp: 'Retira tu producto con este código QR',
        TemplateData: {
          numero_orden: String(customerData.id_order_platform),
          qrCode: `https://${process.env.REACT_APP_S3_BUCKET as string}.s3.amazonaws.com/${s3Key}`
        }
      })
      enqueueSnackbar('Código QR enviado con éxito.', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar('No fue posible hacer el envío del email.: ' + error, { variant: 'error' })
    } finally {
      setData({ ...data, loading: false })
    }
    closeModal()
  }

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setData({ ...data, currentPage: value })
  }

  const syncOrdersToAPI = async () => {
    try {
      const checkOrderStatus = await webApiService.checkOrderStatus()
      console.log('checkOrderStatus =>', checkOrderStatus)
    } catch (error: any) {
      throw new Error('Error al sincronizar órdenes: ' + error.message)
    }
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.active, data.currentPage])

  useEffect(() => {
    if (data.ordenes.length > 0) {
      const latestOrder = data.ordenes[data.ordenes.length - 1]
      sendMessage(JSON.stringify({ eventType: 'orderCreated', orderData: latestOrder }))
    }
  }, [data.ordenes, sendMessage])

  useEffect(() => {
    if (data.modal.open && data.modal.name === 'syncModal' && !data.loading) {
      setData((prevData) => ({
        ...prevData,
        modal: { ...prevData.modal, syncMessage: 'Esto podría tardar unos minutos, por favor espere...' }
      }))

      syncOrdersToAPI()
        .then(() => {
          setData((prevData) => ({
            ...prevData,
            modal: { ...prevData.modal, syncMessage: '¡Sincronizado con éxito!' }
          }))
        })
        .catch((error) => {
          console.error('Error al sincronizar órdenes:', error)
          setData((prevData) => ({
            ...prevData,
            modal: { ...prevData.modal, syncMessage: '¡Oops, hubo un problema en la sincronización.!' }
          }))
        })
    }
  }, [data.modal.open, data.modal.name, data.loading])

  useEffect(() => {
    if (!data.modal.open && data.modal.name === 'syncModal') {
      loadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.modal.open, data.modal.name])

  return (
    <>
      <div className='py-4 px-3' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='h5'>Ordenes con Retiro en Tienda</Typography>
        <div>
          <Button variant='contained' className='primary' size='small' style={{ backgroundColor: '#3f51b5', fontSize: '10px' }} onClick={() => openModal('syncModal')}>
            Sincronizar <CachedIcon />
          </Button>
          <Button variant='contained' size='small' style={{ marginLeft: '10px', backgroundColor: '#9e29be', fontSize: '10px' }} onClick={() => openModal('scanQR')}>
            Escanear código QR <QrCodeIcon />
          </Button>
        </div>
      </div>

      <div className='bg-white rounded shadow py-4 px-3'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <Tabs
              value={data.active}
              onChange={handleTabChange}
              aria-label='Tabs'
              className='mt-2'
            >
              <Tab label='Pendientes' value={1} />
              <Tab label='Entregados' value={0} />
              <Tab label='Ver todo' value={null} />
            </Tabs>
            <TextField
              label='Buscar por orden'
              variant='outlined'
              size='small'
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
        </div>

        {data.loading
          ? (
            <div
              className='bg-white rounded shadow p-5'
              style={{ height: '1000px' }}
            >
              Cargando... <CircularProgress color='primary' size={20} thickness={4} />
            </div>
            )
          : (
            <>
              <div className='container-fluid' style={{ paddingLeft: 0 }}>
                <div className='row'>
                  <div className='col-12'>
                    {data.ordenes.length !== 0
                      ? (
                        <>
                          <TableContainer component={Paper} className='mt-4'>
                            <Table>
                              <TableHead>
                                <TableRow className='small'>
                                  <TableCell className='bg-dark' style={{ color: '#fff' }}>#</TableCell>
                                  <TableCell className='bg-dark' style={{ color: '#fff' }}>Orden</TableCell>
                                  {data.active === 1 ? <TableCell className='bg-dark' style={{ color: '#fff', width: '150px' }}>Estado Shopify</TableCell> : null}
                                  <TableCell className='bg-dark' style={{ color: '#fff' }}>Cliente</TableCell>
                                  <TableCell className='bg-dark' style={{ color: '#fff' }}>Email</TableCell>
                                  <TableCell className='bg-dark' style={{ color: '#fff' }}>
                                    Cantidad de productos
                                  </TableCell>
                                  <TableCell className='bg-dark' style={{ color: '#fff' }}>Total compra</TableCell>
                                  <TableCell className='bg-dark' style={{ color: '#fff' }}>
                                    Fecha compra
                                  </TableCell>
                                  {data.active === 0
                                    ? (
                                      <TableCell className='bg-dark' style={{ color: '#fff' }}>Entregado por</TableCell>
                                      )
                                    : null}
                                  {data.active === 0
                                    ? (
                                      <TableCell className='bg-dark' style={{ color: '#fff' }}>Fecha entrega</TableCell>
                                      )
                                    : null}
                                  <TableCell className='bg-dark' align='left' style={{ color: '#fff', minWidth: '250px' }}>Acciones</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {data.ordenes
                                  .map((e: any, i: number) => {
                                    const startIndex = (data.currentPage - 1) * ITEMS_PER_PAGE
                                    const rowColor = i % 2 === 0 ? evenRowColor : oddRowColor

                                    return (
                                      <TableRow key={startIndex + i} style={{ backgroundColor: rowColor }}>
                                        <TableCell>{startIndex + i + 1}</TableCell>
                                        <TableCell>
                                          {e.id_order_platform}
                                        </TableCell>
                                        {data.active === 1
                                          ? (
                                            <TableCell>
                                              <Chip
                                                label={
                                                e.shopify_status === null ? 'NO PREPARADO' : e.shopify_status === 'Ready for Pickup' ? 'LISTO PARA RETIRO' : e.shopify_status === 'Picked Up' ? 'RETIRADO' : e.shopify_status === 'Picked Up Shopify' ? 'RETIRADO SHOPIFY' : null
                                              }
                                                className='chip-status'
                                                style={{
                                                  backgroundColor: e.shopify_status === 'Ready for Pickup' ? '#1976d2' : e.shopify_status === 'Picked Up' || e.shopify_status === 'Picked Up Shopify' ? '#4caf50' : '#fbc02d',
                                                  color: e.shopify_status === 'Ready for Pickup' ? '#fff' : e.shopify_status === 'Picked Up' || e.shopify_status === 'Picked Up Shopify' ? '#fff' : '#000',
                                                  fontSize: '12px'
                                                }}
                                              />
                                            </TableCell>
                                            )
                                          : (null)}
                                        <TableCell>{e.name}</TableCell>
                                        <TableCell>{e.email}</TableCell>
                                        <TableCell>{e.quantity_products}</TableCell>
                                        <TableCell>{CurrencyFormat(e.total_amount)}</TableCell>
                                        <TableCell>
                                          {moment(e.created_at).format(
                                            'DD/MM/YYYY - HH:mm:ss'
                                          )}
                                        </TableCell>
                                        {data.active === 0
                                          ? (
                                            <TableCell>{e.delivered_by}</TableCell>
                                            )
                                          : null}
                                        {data.active === 0
                                          ? (
                                            <TableCell>{moment(e.updated_at).format(
                                              'DD/MM/YYYY - HH:mm:ss'
                                            )}
                                            </TableCell>
                                            )
                                          : null}
                                        <TableCell align='right'>
                                          {data.active === 1
                                            ? (
                                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Button
                                                  variant='contained'
                                                  size='small'
                                                  href={`/order-details/${e.id_order_platform}`}
                                                  target='_self'
                                                  style={{ fontSize: '10px' }}
                                                >
                                                  Ver orden
                                                </Button>
                                                <Tooltip title='Reenviar QR' arrow>
                                                  <Button
                                                    variant='contained'
                                                    size='small'
                                                    style={{
                                                      marginLeft: '3px',
                                                      fontSize: '10px',
                                                      backgroundColor: '#9e29be',
                                                      display: e.shopify_status === 'Picked Up Shopify' ? 'none' : 'inline-block',
                                                      textAlign: 'center'
                                                    }}
                                                    onClick={() => openModal('sendQR', e)}
                                                  >
                                                    <QrCodeIcon style={{ height: '18px' }} />
                                                  </Button>
                                                </Tooltip>
                                                <Tooltip
                                                  title='Ver código QR' arrow style={{
                                                    display: e.shopify_status === null ? 'none' : 'block'
                                                  }}
                                                >
                                                  <Button
                                                    variant='contained'
                                                    size='small'
                                                    style={{
                                                      marginLeft: '3px',
                                                      fontSize: '10px',
                                                      backgroundColor: '#0d47a1',
                                                      textAlign: 'center'
                                                    }}
                                                    href={`https://${process.env.REACT_APP_S3_BUCKET}.s3.us-west-2.amazonaws.com/qrcodes/${e.id_order_platform}.png`}
                                                    target='_blank'
                                                  >
                                                    <ImageIcon style={{ height: '17px' }} />
                                                  </Button>
                                                </Tooltip>
                                              </div>
                                              )
                                            : data.active === 0
                                              ? (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                  <Button
                                                    className='m-1'
                                                    variant='contained'
                                                    size='small'
                                                    color='success'
                                                    style={{ fontSize: '10px' }}
                                                    onClick={() => openModal('viewDetail', e)}
                                                  >
                                                    Detalles
                                                  </Button>
                                                  <Button
                                                    disabled={e.was_signed === 0}
                                                    className='m-1'
                                                    variant='contained'
                                                    size='small'
                                                    color='primary'
                                                    style={{ fontSize: '10px', minWidth: '80px' }}
                                                    href={`https://${process.env.REACT_APP_S3_BUCKET}.s3.us-west-2.amazonaws.com/receipts/${e.id_order_platform}.pdf`}
                                                    target='_blank'
                                                  >
                                                    Ver Recibo
                                                  </Button>
                                                  <Tooltip title='Ver código QR' arrow>
                                                    <Button
                                                      variant='contained'
                                                      size='small'
                                                      style={{
                                                        marginLeft: '3px',
                                                        fontSize: '10px',
                                                        backgroundColor: '#0d47a1',
                                                        textAlign: 'center'
                                                      }}
                                                      href={`https://${process.env.REACT_APP_S3_BUCKET}.s3.us-west-2.amazonaws.com/qrcodes/${e.id_order_platform}.png`}
                                                      target='_blank'
                                                    >
                                                      <ImageIcon style={{ height: '17px' }} />
                                                    </Button>
                                                  </Tooltip>
                                                </div>
                                                )
                                              : (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                  {e.active === 1
                                                    ? (
                                                      <Button
                                                        variant='contained'
                                                        size='small'
                                                        style={{ fontSize: '10px' }}
                                                        href={`/order-details/${e.id_order_platform}`}
                                                        target='_self'
                                                      >
                                                        Ver orden
                                                      </Button>
                                                      )
                                                    : (
                                                      <Button
                                                        variant='contained'
                                                        size='small'
                                                        color='success'
                                                      >
                                                        Entregada
                                                      </Button>
                                                      )}
                                                </div>
                                                )}
                                        </TableCell>

                                      </TableRow>
                                    )
                                  })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Pagination
                              count={Math.ceil(data.totalRecords / ITEMS_PER_PAGE)}
                              page={data.currentPage}
                              onChange={handlePageChange}
                              color='primary'
                              size='large'
                              className='mt-3'
                            />

                            <Select
                              value={data.currentPage}
                              onChange={handlePageSelectorChange}
                              variant='outlined'
                              size='small'
                              style={{ marginLeft: 10 }}
                            >
                              {Array.from(Array(Math.ceil(data.totalRecords / ITEMS_PER_PAGE)).keys()).map((page) => (
                                <MenuItem key={page + 1} value={page + 1}>
                                  Página {page + 1}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>

                        </>
                        )
                      : (
                        <Typography className='mt-3' variant='h6'>
                          Sin ordenes pendientes...
                        </Typography>
                        )}
                    {/* Modal */}
                    <Modal
                      open={data.modal.open && data.modal.name === 'viewDetail'}
                      onClose={closeModal}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100vh'
                        }}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '90%',
                            maxWidth: '900px',
                            margin: '0 auto',
                            backgroundColor: 'white',
                            padding: 20,
                            borderRadius: 5,
                            position: 'relative'
                          }}
                        >
                          <Typography
                            id='modal-modal-title'
                            variant='h6'
                            component='h2'
                            className='mb-3'
                          >
                            Viendo orden N° <strong>{data.detailsData?.id_order_platform}</strong> - Información de la persona que retiró
                          </Typography>
                          <TableContainer component={Paper} className='mt-4'>
                            <Table>
                              <TableHead>
                                <TableCell className='bg-dark' style={{ color: '#fff' }}>Nombre</TableCell>
                                <TableCell className='bg-dark' style={{ color: '#fff' }}>RUT</TableCell>
                                <TableCell className='bg-dark' style={{ color: '#fff' }}>Email</TableCell>
                                <TableCell className='bg-dark' style={{ color: '#fff' }}>Teléfono</TableCell>
                                <TableCell className='bg-dark' style={{ color: '#fff' }}>Entregado por</TableCell>
                                <TableCell className='bg-dark' style={{ color: '#fff' }}>Fecha entrega</TableCell>
                              </TableHead>
                              <TableBody>
                                <TableCell style={{ backgroundColor: '#f9f9f9' }}>{data.detailsData?.collected_by_name}</TableCell>
                                <TableCell style={{ backgroundColor: '#f9f9f9' }}>{data.detailsData?.collected_by_rut}</TableCell>
                                <TableCell style={{ backgroundColor: '#f9f9f9' }}>{data.detailsData?.collected_by_email}</TableCell>
                                <TableCell style={{ backgroundColor: '#f9f9f9' }}>{data.detailsData?.collected_by_phone}</TableCell>
                                <TableCell style={{ backgroundColor: '#f9f9f9' }}>{data.detailsData?.delivered_by}</TableCell>
                                <TableCell style={{ backgroundColor: '#f9f9f9' }}>
                                  {moment(data.detailsData?.updated_at).format(
                                    'DD/MM/YYYY - HH:mm:ss'
                                  )}
                                </TableCell>
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <div style={{ marginTop: 20, marginLeft: 'auto' }}>
                            <Button
                              variant='contained'
                              color='error'
                              onClick={closeModal}
                            >
                              Cerrar
                            </Button>
                          </div>
                        </Box>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </>
            )}
        <Modal
          open={data.modal.open && data.modal.name === 'scanQR'}
          onClose={closeModal}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box
            style={{
              width: '100%',
              margin: '0 auto',
              backgroundColor: 'white',
              padding: 20,
              borderRadius: 5,
              position: 'relative'
            }}
          >
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Escaneo de código QR
            </Typography>
            <Typography id='modal-modal-description' sx={{ mt: 2, mb: 2 }}>
              Favor mantenga el codigo QR en la parte centrada de su cámara.
            </Typography>
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{ width: '100%' }}
              constraints={{
                video: {
                  facingMode: 'environment'
                }
              }}
            />
            <Button
              variant='contained'
              color='error'
              onClick={closeModal}
              style={{ marginTop: 10 }}
            >
              Cancelar
            </Button>
          </Box>
        </Modal>
        <Modal
          open={data.modal.open && data.modal.name === 'sendQR'}
          onClose={closeModal}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '100vh',
              backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }}
          >
            <Box
              style={{
                width: '100%',
                maxWidth: '600px',
                backgroundColor: 'white',
                borderRadius: 5,
                textAlign: 'center'
              }}
            >
              <Typography id='modal-modal-title' variant='h6' component='h2' style={{ backgroundColor: '#333', color: '#fff', width: '100%', padding: 20, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                Reenvío de código QR
              </Typography>
              <Typography id='modal-modal-description' sx={{ mt: 2, mb: 2 }} style={{ padding: 20 }}>
                El correo será reenviado al titular de la compra{' '}<br />
                {data.detailsData !== null && (
                  <strong>{data.detailsData.email}</strong>
                )} <br />
                ¿Está seguro de que desea reenviar el código QR?
              </Typography>
              <div style={{ backgroundColor: '#f1f1f1', width: '100%', padding: 15, borderBottomLeftRadius: 5, borderBottomRightRadius: 5, paddingBottom: 20 }}>
                <Button
                  variant='contained'
                  size='small'
                  color='error'
                  onClick={closeModal}
                  style={{ marginTop: 10 }}
                >
                  Cancelar
                </Button>
                <Button
                  variant='contained'
                  size='small'
                  color='primary'
                  style={{ marginTop: 10, marginLeft: 10 }}
                  onClick={() => handleAcceptQRConfirmation(data.detailsData)}
                >
                  Aceptar
                </Button>
              </div>

            </Box>
          </Box>
        </Modal>
        <Modal
          open={data.modal.open && data.modal.name === 'syncModal'}
          onClose={closeModal}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '100vh',
              backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }}
          >
            <Box
              style={{
                width: '100%',
                maxWidth: '600px',
                backgroundColor: 'white',
                borderRadius: 5,
                textAlign: 'center'
              }}
            >
              <Typography id='modal-modal-title' variant='h6' component='h2' style={{ backgroundColor: '#333', color: '#fff', width: '100%', padding: 20, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                Sincronizar órdenes con <img src={shopifyLogo} alt='Shopify Logo' style={{ height: '30px' }} />
              </Typography>
            </Box>

            {/* Content */}
            <div style={{ backgroundColor: '#f1f1f1', width: '600px', maxWidth: '100%', padding: 15, paddingBottom: 20, textAlign: 'center' }}>
              <Box>
                <Typography id='modal-modal-description' sx={{ mt: 2, mb: 2 }}>
                  {data.modal.syncMessage}
                </Typography>
                {data.modal.syncMessage === '¡Sincronizado con éxito!' && (
                  <Typography variant='body2' color='textSecondary'>
                    Cierre esta ventana para recargar la página.
                  </Typography>
                )}
              </Box>
            </div>
            {/* Footer */}
            <Box>
              <div style={{ backgroundColor: '#e9e9e9', width: '600px', maxWidth: '100%', padding: 15, borderBottomLeftRadius: 5, borderBottomRightRadius: 5, paddingBottom: 20, textAlign: 'center' }}>
                <Button
                  variant='contained'
                  color='error'
                  onClick={() => {
                    closeModal()
                  }}
                >
                  Cerrar
                </Button>
              </div>
            </Box>
          </Box>
        </Modal>

      </div>
    </>
  )
}

export default HomePage
